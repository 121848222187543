// unfetch is a tiny 500b fetch polyfill
// more info - https://www.npmjs.com/package/unfetch
import { addQueryParam } from '@/utils/url';
import { ApolloClient, HttpLink, InMemoryCache, Operation, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { getMainDefinition } from '@apollo/client/utilities';
import 'unfetch/polyfill';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        imported_git_repository: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ImportedGitRepositoryType',
              id: args.id,
            });
          },
        },
        range_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
        instant_model_metrics: {
          // This allows different model metrics to be stored together in the cache
          merge: true,
        },
      },
    },
    PretrainedModelType: {
      keyFields: ['name'],
    },
    OrganizationType: {
      keyFields: ['name'],
    },
  },
});

// Define the whitelist of queries that should use batching
const batchingWhitelist: string[] = [];

// Helper function to determine if a query is in the whitelist
function shouldBatch(operation: Operation) {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'query' &&
    batchingWhitelist.includes(definition.name?.value || '')
  );
}

// Default HttpLink for non-batched queries
const httpLink = new HttpLink({
  uri: (op) => addQueryParam('/graphql/', 'opName', op.operationName),
});

// BatchHttpLink for batched queries (whitelisted ones)
const batchLink = new BatchHttpLink({
  uri: (op) => addQueryParam('/graphql/', 'opName', op.operationName),
  batchMax: 10, // No more than 5 operations per batch
  batchInterval: 20, // Wait no more than 20ms after first batched operation
});

const apolloClient = new ApolloClient({
  link: split(
    (operation) => shouldBatch(operation), // Use batchLink for whitelisted queries
    batchLink, // Batch whitelisted queries
    httpLink, // Fallback to regular httpLink for others
  ),
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

export { cache };
export default apolloClient;
